import {
  COPPER,
  SUBSCRIPTION_BRIEFING_COPPER,
  SUBSCRIPTION_FORECASTS_COPPER,
  SUBSCRIPTION_SERVICE_COPPER,
} from '~/constants/services/constants'
import { useAuthorization } from '~/composables/useAuthorization'

export default defineNuxtRouteMiddleware(async (to) => {
  const { hasMemberships } = useAuthorization()
  // We can read params this way, see definePageMeta on @/pages/copper/index.vue
  // const market = to.meta.marketSlug

  const allowedMemberships = [
    SUBSCRIPTION_FORECASTS_COPPER,
    SUBSCRIPTION_BRIEFING_COPPER,
    SUBSCRIPTION_SERVICE_COPPER,
  ]
  const marketUnAuthPath = `/${COPPER.slug}`
  const premiumMarketPath = `${marketUnAuthPath}/portal`
  const toPath = to.path.replace(/\/$/, '')

  const hasCopperMembership = await hasMemberships(allowedMemberships)

  if (hasCopperMembership && toPath === premiumMarketPath) return

  if (hasCopperMembership) {
    return navigateTo(premiumMarketPath, { redirectCode: 302 })
  }
})
